import React from 'react';
import {Link} from 'react-router-dom'

 const Footer =  ()=>(      
      
    <footer>
    <div className="footer_top">
    <div className="container-fluid">
        <div className="row">
        <div className="col-md-5 col-sm-5">
            <div className="footer_logo">
            <span><img alt="Easy-Run-Logo-Header.png" src={require('./../../images/Easy-Run-Logo-Header.png')}/></span>
            <span className="footer_logo_name">Easy Run</span>
            </div>
        </div>	
        <div className="col-md-4 col-sm-4">
            <div className="footer_links">
            <h4>ABOUT</h4>
            <Link to={'/about-us'}>ABOUT US</Link>
            {/* <Link to={'/faq'}>FAQ</Link> */}
            <Link to={'/privacy-policy'}>PRIVACY POLICY</Link>
            <Link to={'/terms-of-service'}>TERMS OF USE</Link>
            {/* <Link to={'mailto:support@easyrun.com.au'}>CONTACT US</Link> */}
            <a href={"mailto:" + "support@easyrun.com.au"}>CONTACT US</a>
            </div>
        </div>
        <div className="col-md-3 col-sm-3">
            <h4>FOLLOW US</h4>
            <div className="social_icons">
            <a href="https://twitter.com/easyrunau" target="_blank"><i className="fa fa-twitter" aria-hidden="true" /></a>

            <a href="https://www.facebook.com/easyrunau/" target="_blank"><i className="fa fa-facebook" aria-hidden="true" /></a>

            <a href="https://www.instagram.com/easyrunau/" target="_blank"><i className="fa fa-instagram" aria-hidden="true" /></a>

            <a href="https://www.pinterest.com.au/easyrunau/" target="_blank"><i className="fa fa-pinterest-p" aria-hidden="true" /></a>

            </div>
        </div>	
        </div>
    </div>
    </div>
    <div className="footer_bottom">
    <div className="container-fluid">
        <span>COPYRIGHT © 2020 EASYRUN  |  ABN 71450909513  |  ALL RIGHTS RESERVED</span>
    </div>
    </div>
</footer>    
  
 );

export default Footer;