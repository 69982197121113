import React, { Component } from 'react';	

import Header from './auth/Header';	
import Footer from './auth/Footer';	
import { connect } from 'react-redux';	
import { getHomeDealsAction, getDealsAction,sendLikeAction, clearDealsReducer, clearLikesReducerAction } from '../actions/dealsAction';	
import { clearAction } from '../actions/postDealAction'	
import { Link } from 'react-router-dom';	
import {imagePath, likeStyle, dislikeStyle} from '../helpers/constants'	
import SideBar from './sideBar';	
import {clearTokenVerificationMsgAction,clearReducerAction} from '../actions/auth/authAction'	
import {clearResetPwdTokenVerificationMsgAction} from '../actions/auth/passwordAction'	
import ReduxLazyScroll from 'redux-lazy-scroll'	
import {returnUserType} from '../helpers/functions'	
import HomeHeaderFilter from './homeHeaderFilter';	
class Home extends Component {	
  constructor(props) {	
    super(props)	
    this.renderDeals = this.renderDeals.bind(this)	
    this.getLikeDislike = this.getLikeDislike.bind(this)	
    this.loadPosts = this.loadPosts.bind(this);	
    this.showAll = this.showAll.bind(this);	
    this.showCount = this.showCount.bind(this);	
    this.onChangeFilter = this.onChangeFilter.bind(this);	
    this.state = {	
      showAll: false,	
      filterData:{	
        toggleExpired:0,	
        filterBy:'new',	
      }	
    }	
  }	
  showAll = ()=>{	
    const {showAll, filterData} = this.state	
    if(showAll){	
      window.scrollTo(0, 0)	
     this.props.clearDealsReducer();	
     this.props.getDeals(0,20, filterData);	
    }	
this.setState({	
  showAll:!this.state.showAll	
})	
  }	
  onChangeFilter = (data) =>{	
    this.setState({filterData:data})	
    window.scrollTo(0, 0)	
    this.props.clearDealsReducer();	
    this.props.getDeals(0,20, data);	
  }	
  loadPosts() {	
    	
    const {skip, limit} = this.props	
    const {filterData} = this.state	
    //console.log("limit", limit);	
    //console.log("skip", skip);	
    this.props.getDeals(skip,limit, filterData);	
  }	
  	
  componentDidMount() {	


    window.scrollTo(0, 0)	
  const {filterData} = this.state	
     this.props.getDeals(0,20, filterData);	
     setTimeout(() => {	
    this.props.clearPostDealReducer();	
       	
     }, 3000);	
  }	
  componentWillUnmount() {	
    this.props.clearPostDealReducer();	
    this.props.clearReducer();	
    this.props.clearDealsReducer();	
    this.props.clearTokenVerificationMsg()	
    this.props.clearPwdTokenVerificationMsg()	
    this.props.clearLikesReducer()	
   
  }	
  getLikeDislike=(status,id)=>{	
 	
    if(this.props.userInfo){	
     	
      this.props.sendLike(status,id);	
    }else{	
      this.props.history.push('/login')	
    }	
  }	
  showCount=( likeCountId, dealId , del_count_like , del_count_dislike)=>{	
  	
 const {count} = this.props	
 let cnt = null;	
 count && count.map((item)=>{	
if(item.id == dealId){	
cnt = item.lcount	
}	
 })	
 if(cnt!=null){	
  return cnt.toString()	
 }else return (del_count_like - del_count_dislike).toString()	
   	
  }	
  componentWillReceiveProps(nextProps){	
 	
 	
  }	
  render() {	
    const { loader, postResult,deals, verificationResult, regResult, pwdVerificationError } = this.props	
    	
    const {showAll} = this.state	
    return (	
      <div>	
        <Header {...this.props}/>	
        <section className="main">	
          <div className="container-fluid">	
            <div className="row">	
              <div className="col-lg-9 col-md-8">	
                <div className="product_list">	
                  {postResult &&  <div class="alert alert-success alert-dismissible">	
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>	
                    <strong>Deal </strong>Posted successfully	
  </div>}	
  {this.props.dealUpdateResult && <div className="alert alert-success alert-dismissible text-center mt-2">	
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>	
                              Deal updated successfully	
  </div>}	
  { regResult =='success' && (<div className="alert alert-success text-center" > { 'Successfully registered. Please check your mail for the verification link' } </div>) }	
  { verificationResult=='error' && (<div className="alert alert-danger text-center" > {"Verification failed. Please contact your admin" } </div>) }	
  { pwdVerificationError=='error' && (<div className="alert alert-danger text-center" > {"Verification failed. Please contact your admin" } </div>) }	
{this.renderDeals()}	
          	
          <div className="container text-center">	
{!loader  ? deals&& deals.length>=20? <button className="btn btn-secondary" onClick={()=>this.showAll()} >{showAll?"Show less":"Show All"}</button>:'':<div className="spinner-border"></div>}	
          </div>	
           	
                 	
                	
                 	
                </div>	
              </div>	
              <SideBar {...this.props} />	
            </div>	
          </div>	
        </section>	
        <Footer />	
      </div >	
    )	
  }	
  renderDeals() {	
    const {loader,error,hasMore,  deals,userInfo ,likeIds, dislikeIds,likeCountId,count} = this.props	
const {showAll} = this.state	
if(!showAll){	
  return ( <div className="container posts-lazy-scroll">	
    <HomeHeaderFilter  changeFilter = {this.onChangeFilter} />	
    {!loader? deals&& deals.length<=0 ?<p className="text-center mt-5">No deals found!</p>:'':''}	
    <ul>	
  {	
deals && deals.map((deal, idx) => {	
  	
return (	
  <li key={idx}>	
    <div className={deal.del_is_featured=="1"?"product_item featured-item": "product_item"}>	
      <div className="badges d-flex justify-content-between align-items-start">	
     {deal.user.role!=4? <div className="bg-usrType">{returnUserType(deal.user.role)}</div>:<div></div>}	
     <div>	
     {deal.tags&& deal.tags.map((tag, idx)=> <div id={`color-${tag.id}`} className="bg-status text-center  mb-1">{tag.tag_name}</div>  )}	
     </div>	
      	
      {/* <div className="bg-status">New</div> */}	
      </div>	
    	
      <div className="item_image ">	
      	
        <Link to={`/deal/${deal.id}`}>{deal.del_image_primary?<img alt={deal.del_image_primary} src={`${imagePath}/${deal.id}/${deal.del_image_primary}`} />:<img alt="no image" src={require('../images/noimage.jpg')} />}</Link>	
      </div>	
      <div className="item_title">	
        <a  href={`/deal/${deal.id}`}> {deal.del_title}</a>	
      </div>	
      <div className="item_price">	
      	
        {deal.del_price ?`$${deal.del_price.toFixed(2)}`:'$0.00'}	
        {deal.stricken_price ?<span class="deal_old_price pl-1"> {`$${deal.stricken_price}`}</span>:''}	
       	
      </div>	
      <div className="voting_icons">	
        <span className="voting_label">	
          <span className={`likes_icon`} onClick = {()=>this.getLikeDislike('1',deal.id)}><i className="fa fa-thumbs-up" aria-hidden="true"  style={likeIds.includes(deal.id)?likeStyle:{'border':'0', 'cursor':'pointer'}} /></span>	
          <span id={`dealCount_${deal.id}`} className="likes_count">{this.showCount(likeCountId, deal.id, deal.del_count_like, deal.del_count_dislike)}</span>	
          <span className="dislikes_icon" onClick = {()=>this.getLikeDislike('0',deal.id)}   ><i className="fa fa-thumbs-down" aria-hidden="true" style={dislikeIds.includes(deal.id)?dislikeStyle:{'border':'0','cursor':'pointer'}} /></span>	
        </span>	
        {deal.del_active==0? <span className="badges pl-2">	
        <span className="bg-expired">Expired</span>	
        </span>:''}	
        <span className="comment_label">	
       <span className="comment_icon">	
        <Link	
        to={{ pathname: `/deal/${deal.id}`, query: { catch: 'comments' } }}	
        	
       > <i className="fa fa-comment" aria-hidden="true" /></Link>	
         </span>	
          <span className="comment_count">{deal.del_count_comments}</span>	
        </span>	
        <div className="clearfix"></div>	
      </div>	
      <div className="item_details_btn_wrap">	
        {/* <a href="#" className=" btn btn-primary item_details_btn"> View Details</a> */}	
        <Link className=" btn btn-primary item_details_btn" to={`/deal/${deal.id}`} >View Details </Link>	
      </div>	
    </div>	
  </li>	
)	
})	
  }	
</ul>   </div>)    	
  	
  	
  	
  	
}else	
   return(	
   	
    <div className="container posts-lazy-scroll">	
  <HomeHeaderFilter  changeFilter = {this.onChangeFilter} />	
   <ReduxLazyScroll	
      isFetching={loader}	
      errorMessage={error}	
      loadMore={this.loadPosts}	
      hasMore={hasMore}	
    >	
      <ul>	
      {deals.map((deal, idx) => (	
         <li key={idx}>	
         <div className={deal.del_is_featured=="1"?"product_item featured-item": "product_item"}>	
         <div className="badges d-flex justify-content-between align-items-start">	
     {deal.user.role!=4? <div className="bg-usrType">{returnUserType(deal.user.role)}</div>:<div></div>}	
     <div>	
     {deal.tags&& deal.tags.map((tag, idx)=> <div id={`color-${tag.id}`} className="bg-status text-center  mb-1">{tag.tag_name}</div>  )}	
     </div>	
      	
      {/* <div className="bg-status">New</div> */}	
      </div>	
           <div className="item_image ">	
           	
             <Link to={`/deal/${deal.id}`}>{deal.del_image_primary?<img alt={deal.del_image_primary} src={`${imagePath}/${deal.id}/${deal.del_image_primary}`} />:<img alt="no image" src={require('../images/noimage.jpg')} />}</Link>	
           </div>	
           <div className="item_title">	
             <a  href={`/deal/${deal.id}`}> {deal.del_title}</a>	
           </div>	
           <div className="item_price">	
           	
             {deal.del_price ?`$${deal.del_price.toFixed(2)}`:'$0.00'} 	
             {deal.stricken_price ?<span class="deal_old_price pl-1"> {`$${deal.stricken_price}`}</span>:''}	
            	
           </div>	
           <div className="voting_icons">	
             <span className="voting_label">	
               <span className={`likes_icon`} onClick = {()=>this.getLikeDislike('1',deal.id)}><i className="fa fa-thumbs-up" aria-hidden="true"  style={likeIds.includes(deal.id)?likeStyle:{'border':'0', 'cursor':'pointer'}} /></span>	

               {/* <span id={`dealCount_${deal.id}`} className="likes_count">{likeCountId==deal.id &&(count!=null)?count: (deal.del_count_like - deal.del_count_dislike)}</span> */}

               <span id={`dealCount_${deal.id}`} className="likes_count">{this.showCount(likeCountId, deal.id, deal.del_count_like, deal.del_count_dislike)}</span>	

               <span className="dislikes_icon" onClick = {()=>this.getLikeDislike('0',deal.id)}   ><i className="fa fa-thumbs-down" aria-hidden="true" style={dislikeIds.includes(deal.id)?dislikeStyle:{'border':'0', 'cursor':'pointer'}} /></span>	
             </span>	
            {deal.del_active==0? <span className="badges pl-2">	
        <span className="bg-expired">Expired</span>	
        </span>:''}	
             <span className="comment_label">	
            <span className="comment_icon">	
             <Link	
             to={{ pathname: `/deal/${deal.id}`, query: { catch: 'comments' } }}	
             	
            > <i className="fa fa-comment" aria-hidden="true" /></Link>	
              </span>	
               <span className="comment_count">{deal.del_count_comments}</span>	
             </span>	
             <div className="clearfix"></div>	
           </div>	
           <div className="item_details_btn_wrap">	
            	
             <Link className=" btn btn-primary item_details_btn" to={`/deal/${deal.id}`} >View Details </Link>	
           </div>	
         </div>	
       </li>	
        ))	
      }	
      </ul>	
   	
    </ReduxLazyScroll>	
    <div className="row posts-lazy-scroll__messages">	
      {loader && <div className="spinner-border"> </div>}	
      {/* {!hasMore && !error &&	
        <div className="alert alert-success">All the posts has been loaded successfully.</div>	
      } */}	
      {/* {error && <div className="alert alert-danger">{"Some error occured while fetching deals"}</div>} */}	
    </div>	
  </div>	
   )	
   	
  }	
}	
const mapStateToProps = state => ({	
  loader: state.dealsReducer.loader,	
  deals: state.dealsReducer.result,	
  error: state.dealsReducer.error,	
  hasMore: state.dealsReducer.hasMore,	
  skip: state.dealsReducer.skip,	
  limit: state.dealsReducer.limit,	
  userInfo:state.profileReducer.result,	
  likeIds:state.likeReducer.likeIds,	
  dislikeIds:state.likeReducer.dislikeIds,	
  likeCountId:state.likeReducer.likeCountId,	
  likeStatus:state.likeReducer.like,	
  dislikeStatus:state.likeReducer.dislike,	
  count:state.likeReducer.count,	
  postResult:state.postDealReducer.result,	
  verificationResult:state.tokenAuthReducer.result,	
  pwdVerificationError:state.pwdTokenAuthReducer.result,	
  regResult:state.authReducer.result,	
  dealUpdateResult: state.postDealReducer.updateResult,	
})	
const mapDispatchToProps = dispatch => ({	
  // getDeals: (skip, limit) => dispatch(getDealsAction(skip, limit)),	
  getDeals: (skip, limit, filterData) => dispatch(getHomeDealsAction(skip, limit, filterData)),	
  clearPostDealReducer: () => dispatch(clearAction()),	
  sendLike: (status,id) => dispatch(sendLikeAction(status,id)),	
  clearReducer: () => dispatch(clearReducerAction()),	
  clearDealsReducer: () => dispatch(clearDealsReducer()),	
  clearTokenVerificationMsg: () => dispatch(clearTokenVerificationMsgAction()),	
  clearPwdTokenVerificationMsg: () => dispatch(clearResetPwdTokenVerificationMsgAction()),	
  clearLikesReducer: () => dispatch(clearLikesReducerAction()),	
})	
export default connect(mapStateToProps, mapDispatchToProps)(Home);